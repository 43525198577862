<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h2 class="container ls-page-header-title">
        Medical Prescription Request
      </h2>
      <h3 class="container ls-page-header-subtitle"></h3>
    </div>

    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
        <div class="col-xl-6 col-lg-6 col-md-8 col-12">
          <form
            class="ps-form--account-setting"
            method="get"
            enctype="multipart/form-data"
          >
            <div class="ps-form__content mt-3">
              <div class="row mt-3 mb-3" v-if="message">
                <div class="col-12">
                  <small class="text-danger" v-if="isError">{{
                    message
                  }}</small>
                  <small class="text-success" v-else>{{ message }}</small>
                </div>
              </div>
              <div class="form-group">
                <label>Shop <sup>*</sup> </label>
                <div class="form-group__content">
                  <select class="form-control" v-model="shop_id">
                    <option v-for="shop in shops" :value="shop.id">{{
                      shop.name
                    }}</option>
                  </select>
                  <small
                    v-if="errors && errors.file"
                    class="phone_error text-danger"
                  >
                    {{ errors.file[0] }}
                  </small>
                </div>
              </div>

              <div class="form-group">
                <label>Upload Doctor's Prescription <sup>*</sup> </label>
                <div class="form-group__content">
                  <input
                    type="file"
                    name="filename"
                    v-on:change="onFileChange"
                  />

                  <p class="mt-2">
                    Selected file: <b>{{ file ? file.name : "" }}</b>
                  </p>
                  <small
                    v-if="errors && errors.file"
                    class="phone_error text-danger"
                  >
                    {{ errors.file[0] }}
                  </small>
                </div>
              </div>

              <div class="form-group">
                <label>Notes <sup>*</sup> </label>
                <div class="form-group__content">
                  <textarea class="form-control" v-model="notes"></textarea>
                  <small v-if="errors && errors.notes" class="text-danger">
                    {{ errors.notes[0] }}
                  </small>
                </div>
              </div>
            </div>
            <div class="ps-form__content text-right">
              <div class="form-group">
                <button
                  class="btn btn-primary ls-button"
                  @click.prevent="onSubmit()"
                >
                  {{ isLoading ? "Processing..." : "Submit" }}
                  <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
                  <span
                    v-if="isLoading"
                    class="spinner-border text-light"
                    role="status"
                  ></span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreatePrescriptionMedicationRequest",
  data() {
    return {
      errors: {},
      total: 0,
      lines: [],
      isRemoving: false,
      isLoading: false,
      isError: false,
      message: null,
      file: null,
      notes: null,
      shop_id: null,
      shops: [],
      prescriptionFile: null,
    };
  },
  mounted() {
    axios.get("shops").then((response) => {
      this.shops = response.data.data;
    });
  },
  methods: {
    onFileChange(e) {
      // console.log(e.target.files[0]);
      this.filename = "Selected File: " + e.target.files[0].name;
      this.file = e.target.files[0];
    },

    clearFiles() {
      this.$refs["file-input"].reset();
    },

    handleFileUpload() {
      // this.prescriptionFile = ;
    },

    onSubmit() {
      if (!this.file || !this.notes || !this.shop_id) {
        alert("Please fill in all details");
      } else {
        this.isLoading = true;

        let formData = new FormData(); // Initialize the form data
        formData.append("file", this.file); // Add the form data we need to submit
        formData.append("notes", this.notes); // Add the form data we need to submit
        formData.append("shop_id", this.shop_id); // Add the form data we need to submit
        axios
          .post("medicalprescriptions/create", formData, {
            "content-type": "multipart/form-data",
          })
          .then((response) => {
            this.isLoading = false;
            if (response.status === 200 || response.status === 201) {
              this.message = "Prescription request successfully created";
              this.$router.push({ name: "prescriptions" });
            }
          })
          .catch((error) => {
            console.log(error);
            // if (error.response.status === 422) {
            //   this.message = "Prescription request could not be created. Please enter all required fields.";
            //   this.errors = error.response.data.errors
            // } else {
            //   this.message = error.backendErrors[0]
            // }
            // this.isError = true
            // this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
